"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var tslib_1 = require("tslib");
var xhr_1 = require("../src/utils/xhr");
var url_parser_1 = require("../src/utils/url-parser");
var cookie_utils_1 = require("../src/utils/cookie-utils");
window.okRecommendCheck = function (rootUrl) {
    var self = this;
    if (!rootUrl)
        throw "root Url is required";
    self.rootUrl = rootUrl;
    var defaultHeaders = { 'Content-Type': 'application/json', 'Accept': 'application/json' };
    var tenantName = getTenantName(self.rootUrl);
    var TRACKER_REQUEST_HEADER_KEY = 'xd-oksky-tracker-token';
    var checkModeList = [0, 1];
    self.check = function (checkMode, checkType, callback) {
        if (!callback) {
            console.error('コールバック関数が設定されてません');
            return;
        }
        if (checkMode === null || typeof checkMode === 'undefined' || !Number.isInteger(checkMode)) {
            console.error('checkModeは整数である必要があります');
            callback(errorDefaultResult());
            return;
        }
        if (!checkModeList.includes(checkMode)) {
            console.error('checkModeの値が不正です');
            callback(errorDefaultResult());
            return;
        }
        if (checkMode === 0) {
            if (checkType === null || typeof checkType === 'undefined' || !Number.isInteger(checkType)) {
                console.error('checkModeは整数である必要があります');
                callback(errorDefaultResult());
                return;
            }
            if (checkType < 0 || checkType > 99) {
                console.error('checkModeの値が不正です');
                callback(errorDefaultResult());
                return;
            }
        }
        checkCookieAndCheckStart(checkMode, checkType, callback);
    };
    function checkTrackerToken() {
        var inputElement = document.getElementById('pv-tracker');
        var value = null;
        if (inputElement && inputElement.type === 'hidden') {
            value = inputElement.value;
        }
        var cookieValue = cookie_utils_1.getCookie("xd-" + tenantName + "_oksky-tracker-token");
        if (cookieValue !== null && cookieValue !== "" && value === "1") {
            return true;
        }
        return false;
    }
    function checkCookieAndCheckStart(checkMode, checkType, callback) {
        if (checkTrackerToken()) {
            getTrackeRecommendCheck(checkMode, checkType, callback);
            return;
        }
        startInterval(checkMode, checkType, callback);
    }
    function startInterval(checkMode, checkType, callback) {
        var interval = null;
        var okFlg = false;
        interval = setInterval(function () {
            if (okFlg)
                return;
            if (checkTrackerToken()) {
                okFlg = true;
                clearInterval(interval);
                interval = null;
                getTrackeRecommendCheck(checkMode, checkType, callback);
            }
        }, 500);
    }
    function getTrackeRecommendCheck(checkMode, checkType, callback) {
        var query = {};
        query.url = url_parser_1.urlJoin(self.rootUrl, '/rapi/v1/tracker_recommend_checks/tracker_check');
        query.method = "POST";
        var xdCookieValue = "-";
        var xAccessToken = "";
        if (document.cookie) {
            var xAccessTokenHash = document.cookie.split("; ").filter(function (s) { return s.match(/^xd-access_token/); }).map(function (s) { return s.replace(/^xd-access_token=/, ''); });
            xAccessToken = xAccessTokenHash[0] || "";
        }
        query.headers = tslib_1.__assign({}, defaultHeaders, { "Xd-Cookie": xdCookieValue, "X-Access-Token": xAccessToken });
        query.headers[TRACKER_REQUEST_HEADER_KEY] = cookie_utils_1.getCookie("xd-" + tenantName + "_oksky-tracker-token");
        query.data = JSON.stringify({
            data: {
                type: "tracker_recommend_checks",
                attributes: {
                    checkMode: checkMode,
                    checkType: checkType
                }
            }
        });
        query.onComplete = function (event) {
            if (event.status != 200 && event.status != 201) {
                console.error("Error " + event.status + ": " + event.statusText);
                callback(errorDefaultResult());
            }
            else {
                var resultData = JSON.parse(event.responseText);
                callback(resultData);
            }
        };
        xhr_1.xhr(query);
    }
    function errorDefaultResult() {
        return {};
    }
    function getTenantName(url) {
        var domain = (new URL(url)).hostname;
        return domain.split('.')[0];
    }
};
